import React from 'react'
import {Link} from 'gatsby'

const CaseStudiesCard = props => {

    const { csName, csImage, csExcerpt, csPermalink } = props.data

    return (
        <div className="case-studies-card">
            <div className="case-studies-card__img">
                <img src={csImage ? csImage : 'https://images.pexels.com/photos/1759530/pexels-photo-1759530.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'} alt={csName}/>
            </div>
            <div className="case-studies-card__content">
                <div className="case-studies-card__title">{csName}</div>
                <p>{csExcerpt}</p>
                <Link to={csPermalink} className="btn btn-primary">View Case Study</Link>
            </div>
        </div>
    )
}

export default CaseStudiesCard