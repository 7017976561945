import React from 'react';
import {Jumbotron} from 'react-bootstrap';
import Layout from '../components/layout';
import CaseStudiesCard from '../components/CaseStudiesCard'
import '../styles/case-studies.css';

const caseStudies = [
    {
        "csName": "Akshat Siddharth",
        "csImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/case-studies/student-case-study-akshat-cover.jpg",
        "csExcerpt": "A study on why Akshat Siddharth chose to up-skill himself with the full-stack development course offered by Crampete.",
        "csPermalink": "/case-studies/akshat-siddharth/"
    },
    {
        "csName": "Manimegalan",
        "csImage": "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/case-studies/student-case-study-manimegalan-cover.jpg",
        "csExcerpt": "From being a professor to a software developer - Manimegalan's journey with Crampete",
        "csPermalink": "/case-studies/manimegalan/"
    }
]

const CaseStudies = () => {
    return (
        <Layout title="Case Studies | Crampete">
            <Jumbotron className="bg-lightblue">
                <div className="container">
                <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
                    <h2>Student Case Studies</h2>
                    <p className="mt-4">Below you'll find case studies on what makes Crampete a personal favourite amongst students looking to up-skill themselves</p>
                </div>
                </div>
            </Jumbotron>
            <div className="container">
                <div className="case-studies-wrapper">
                    <div className="row">
                        {caseStudies.map((caseStudy,index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="col-md-3">
                                        <CaseStudiesCard data={caseStudy} />
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CaseStudies